.app-main-menu {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        .ant-drawer-title {
          font-weight: bold;
        }
      }
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
}
