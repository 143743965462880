$color-coding-performance: #daeaff;
$color-financial: #feffda;
$color-people: #dafff4;
$color-relationship: #fff2fa;
$color-risk:#FBEEEE;
$color-performance:#e5ebd6;
$color-quality:#d5d5fc;
$color-coding-performance-btn: #006eff;
$color-financial-btn: #cea802;
$color-people-btn: #05ca8f;
$color-relationship-btn: #ff0fa3;
$color-risk-btn:#ff0909;
$color-Click:#b71c1c;
