.page-target-configuration-button {
  border-radius: 5px;
  border: 0;
}

th .ant-table-cell {
  font-size: 12px;
}

.ant-input-number {
  width: 50px;
}

.custom-metrics-container {
  .custom-metric-options {
    text-align: right;
    .custom-metric-label {
      margin-left: 5px;
    }
  }
}
