@use '../../theme/color.scss';

$color-null: #ccc;
$color-below-target: #ffc7ce;
$color-on-target: #bdd6ff;
$color-above-target: #5e9bff;

.project-monthly-data-table {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: bold;
      text-align: center;
      padding-left: 6px;
      padding-right: 6px;

      &.monthly-column-group-odd {
        background-color: #bbb;
      }
      &.monthly-column-group-even {
        background-color: #e0e0e0;
      }

      &.monthly-data-name-column,
      &.monthly-data-engagement-column {
        text-transform: uppercase;
      }
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      &[data-target='null'] {
        background-color: $color-null;
      }

      &.monthly-data-name-column {
        &[data-theme='codingPerformance'] {
          background-color: color.$color-coding-performance;
        }

        &[data-theme='financial'] {
          background-color: color.$color-financial;
        }

        &[data-theme='people'] {
          background-color: color.$color-people;
        }

        &[data-theme='relationship'] {
          background-color: color.$color-relationship;
        }

        &[data-theme='risk'] {
          background-color: color.$color-risk;
        }
      }

      &.monthly-data-child-column {
        &[data-target='below'] {
          background-color: $color-below-target;
        }

        &[data-target='on'] {
          background-color: $color-on-target;
        }

        &[data-target='above'] {
          background-color: $color-above-target;
        }
      }

      &.monthly-data-column {
        text-align: center;

        &[data-target='below'] {
          background-color: $color-below-target;
        }

        &[data-target='on'] {
          background-color: $color-on-target;
        }

        &[data-target='above'] {
          background-color: $color-above-target;
        }
      }
    }
  }
}
