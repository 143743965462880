@use '../../frontend/src/theme/color.scss'; 
$color-primary: #024fa5;
$color-text: rgba(0, 0, 0, 0.85);
.k-gauge {
  .k-arcgauge-label {
    width: 100%;
  }
}
.section-title-container {
  padding-bottom: 16px;
}
.notify-button {
  border-color: #FFD700 !important;
  background-color:#FFFAF0 !important;
  color: #FFD700 !important;
}
.section-title {
  display: inline-block;
  margin-right: 24px;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: $color-primary;
}

.download-button{
  margin-right: 24px;
  margin-bottom: 8px;
}
.section-title-container {
  .ant-picker {
    margin-right: 24px;
    margin-bottom: 8px;
  }
}

.card-title {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.ant-space-item {
  display: flex;
}

.app-container {
  padding: 12px 12px 0 12px;
  min-height: calc(92vh - 70px);
  .page-container {
    
    .section-container {
      box-shadow: 1px 1px 2px 2px #d6d6d6;
      padding: 15px;
      background-color: #fff;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    .heatmap-container {
      .filter-container {
        text-align: left;
        margin-bottom: 10px;

        .heatmap-measure-options {
          text-align: right;
        }
        .color-scale {
          margin-top: 10px;
        }    
      }
    }

    .heatmap-filter-label {
      font-weight: 600;
      margin-right: 10px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    .heatmap-filter {
      text-align: left;
      margin-top: 10px;

      .ant-select {
        text-align: left;
        min-width: 250px;
      }
    }

    .gauge-container {
      position: relative;

      .gauge-text {
        width: 100%;
        text-align: center;
        bottom: 0;

        h3 {
          display: inline;
        }
      }

      .kpi-badge {
        position: absolute;
        right: 0;
        bottom: 0;

        &.kpi-decrease {
          color: red;
        }

        &.kpi-increase {
          color: green;
        }

        .kpi-indicator {
          display: inline-block;
          vertical-align: text-bottom;
        }
      }
    }
  }
}

@media only screen and (max-width: 415px) {
    .app-container{
      padding: 12px 12px 0 12px;
      .chart-Program {
        width: 100%;
      }
      .page-container{
        .section-padding {
          padding: 12px 16px;
          width: 100%;
        }
        .section-container{
          padding: 12px 16px;
          .fullwitdh-chart{
            .ant-col{
              width: 100%;
            }
          }
        }
      }
      .comment-section{
        .comment-section__container{
          padding: 5px 8px 5px 8px;
          .ant-comment.comment-section__text {
            margin-left: 16px;
            .ant-comment-nested{
              margin-left: 22px;
            }
          }
        }
      }
      .section-container {
        box-shadow: 1px 1px 2px 2px #d6d6d6;
        padding: 24px 32px;
        background-color: #fff;
  
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
}
.page-footer {
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ant-page-header.site-page-header.ant-page-header-ghost {
  background-color: white;
  box-shadow: 5px 5px 5px #ddd;
  position: relative;

  .ant-page-header-content {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    .ant-space-item {
      .ant-btn.ant-btn-icon-only {
        display: contents;
      }
    }
  }
}

.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .user-container-avatar {
    display: inline-block;
  }
  .user-container-team-select {
    margin-right: 16px;
    width: 200px;
  }

  .user-container-team-select-mobile {
    margin: 10px;
    width: 235px;
  }
}

.logo-holder{
  height: 100%;
}

.ant-table table {
  .ant-table-cell-fix-left{
    position: sticky;
    left: 0px;
    text-overflow: ellipsis; 
    word-wrap: break-word;
  }
}
@media only screen and (max-width: 600px) {
  .ant-table table {
    .ant-table-cell-fix-left{
      position: sticky;
      left: 0px;
      text-overflow: ellipsis; 
      word-wrap: break-word;
      max-width: 80px !important;
    }
    .sticky {
      width: 60% !important;
    }
  }
}

@media only screen and (max-width: 14000px) {
  .ant-page-header.site-page-header.ant-page-header-ghost {
    .ant-page-header-content {
      .ant-space-item {
        .ant-btn.ant-btn-text.ant-btn-lg {
          padding: 16px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .ant-spin-container {
    .section-container {
      .ant-row {
        .ant-col {
          .ljdjHe {
            padding: 16px 0px !important;
          }
        }
      }
    }
  }
}

.scroll-table {
  .ant-table-container {
    overflow-y: auto;
  }
}

.breadCumb_TeamDetail{
  margin-bottom: 12px ;
  margin-left: 15px;
}

.heatmap {
  .ant-table {
    line-height: 1.2;

    a {
      color: $color-text;
      &:hover {
        text-decoration: underline;
      }
    }
    
  }
    .ant-table-cell {
      &.heatmap-column {
        text-align: center;
      }

      &.heatmap-column-score {
        font-weight: bold;
      }
    }
    .ant-table-thead {
      .ant-table-cell {
        .ant-table-column-sorter {
          position: absolute;
          right: 2px;
          top: 0;
        }
      
        .ant-table-column-sorters {
          padding: 16px 0;
        }
      }
      .heatmap-column-notgood{
        background-color: #ff7272;
        font-weight: bolder;
      }
      .heatmap-column-good{
        background-color: #0ba800;
        font-weight: bolder;
      }
      .heatmap-column-nes{
        background-color: #ffefef;
      }
      .heatmap-column-pos{
        background-color: #effff8;
      }
      .heatmap-column-zero{
        background-color: #fff5ce;
      }
      .heatmap-column-score {
        background-color: #f3c300;
        text-align: center;
        text-transform: uppercase;
      }
      .heatmap-column-bbdefb{
        background-color: #bbdefb;
      }
      .heatmap-column-feffda {
        background-color: #feffda;
      }
      .heatmap-column-b2ebf2 {
        background-color: #b2ebf2;
      }
      .heatmap-column-fce4ec {
        background-color: #fce4ec;
      }
      .heatmap-column-ffccc7 {
        background-color: #ffccc7;
      }
      .heatmap-column-c5cae9 {
        background-color: #c5cae9;
      }
      .heatmap-column-dcedc8 {
        background-color: #dcedc8;
      }
      .heatmap-column-c8e6c9  {
        background-color: #c8e6c9;
      }
      .heatmap-column-ffe7ba  {
        background-color: #ffe7ba;
      }
      .heatmap-column-d7ccc8  {
        background-color: #d7ccc8;
      }
      .heatmap-column-coding-per {
        background-color: color.$color-coding-performance;
      }
      .heatmap-column-financial {
        background-color: color.$color-financial;
      }
      .heatmap-column-people {
        background-color: color.$color-people;
      }
      .heatmap-column-nps {
        background-color: color.$color-relationship;
      }
      .heatmap-column-risk {
        background-color: color.$color-risk;
      }
      .heatmap-column-performance {
        background-color: color.$color-performance;
      }
      .heatmap-column-quality {
        background-color: color.$color-quality;
      }
      .ant-table-cell {
        &.heatmap-column {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        &.heatmap-column {
          padding: 0;
        }
      }
    }
  }

