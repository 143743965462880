.heatmap-measure-options{
    margin-bottom: 20px;
    .heatmap-filter-label{
        margin-right: 20px;
        font-size: large;
        font-weight: bold;
    }
}
.weight{
    margin-left: 10px;
    .menu-teamweight{
        margin-top: 10px;
    }
    .weight-configuration{
        margin: 20px 0px 0px 20px;
        .btn-configuration{
            margin-top: 20px;
        }
        .table-weight{
            margin-top: 30px;
        }
    }
}