.ant-tooltip-inner {
    color: rgb(89, 89, 89);
    background-color: white;
    opacity: 0.95;
    margin: 0px; list-style-type: none; padding: 12px 0 2px 0;
}
.ant-tooltip-arrow {
    display: none;
}
.ant-tooltip {
    padding: 12px;
    margin: 12px 0;
}
.tooltip-data-list {
    padding: 0;
}
.span-tooltip-sentiment {
    border-radius: 50%;
    margin: 0px 12px;
    .sentiment-value {
        display: inline-block;
        float: right;
        margin-left: 30px;
    }
    list-style-type: none;
}
.title-tooltip-sentiment {
    margin: 0px 12px;
}
.span-maker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}   
.notgood {
    background: rgb(255, 114, 114);
}
.neu {
    background: rgb(243, 195, 0);
}
.good {
    background: rgb(11, 168, 0);
}
