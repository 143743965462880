.title-import-sentiment {
    font-weight: normal;
}
.datepicker-import-sentiment {
    margin-right: 30px;
}
.btn-browse {
    margin-right: 20px;
}
.btn-upload {
    margin-top: 20px;
}