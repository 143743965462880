.npsIcon {
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 1.3rem;
  cursor: pointer;
}

.npsBarChar {
  overflow-x: auto;

  > div {
    min-width: 600px;
  }
}