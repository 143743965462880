.app-note-drawer {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        .ant-drawer-title {
          font-weight: bold;
        }
      }
      .ant-drawer-body {
        padding: 24px;
      }
    }
  }
}

@media only screen and (max-width: 415px){
  .filter-row_padding {
    padding-left: 5px !important;
    padding-right: 5px !important;

  }
  .filter-row_element{
    .ant-picker.ant-picker-disabled{
      width:100px
    }
  }
  .irAJwI .ant-tabs-content {
    padding: 1ch 2ch !important;
  }
}