@use '../../../theme/color.scss'; 
.missing-actual-tag {
  color:black;
}
.ant-comment {
  &-content {
    &-detail {
      .ant-row.ant-form-item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.ant-col {
  .filter-actions-section {
    background: #fafaff;
    min-height: 100%;
    width: 100%;
    border: none;
    .ant-space.ant-space-vertical {
      width: 100%;
      .ant-space-item {
        .ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left.ant-collapse-ghost {
          .ant-collapse-header {
            padding-left: 30px;
            padding: 6px 30px;
            .ant-collapse-arrow {
              left: 0;
            }
          }
          .ant-collapse-content-box {
            padding-left: 0;
            .ant-timeline {
              &-item {
                padding-bottom: 10px;

                &-content {
                  margin: 0 0 0 14px;
                  top: -12px;
                  .ant-btn.ant-btn-primary {
                  }
                }
              }
            }
          }
        }
      }
      .filter-searchbox {
        width: 100%;
      }
    }
  }
}
.table-missing-measure {
  .ant-table-wrapper {
    width: 100%;
    margin-left: 14px;
    margin-top: 5px;
    .ant-table-thead > tr > th {
      color: white;
      background: #024fa5;
      text-align: center;
      text-transform: uppercase;
    }
    .ant-tag {
      margin: 2px;
    }
  }
}
@media only screen and (max-width: 576px){
  .table-missing-measure{
    .ant-pagination-options{
      display: inline-block;
    }
  }
}
.CommentStyle{
  white-space: pre-line;
}
.btn-healthCheck {
  margin-left: 14px;
  .btn-filter-measure {
    margin-left: 5px;
    margin-bottom: 5px;
    width: max-content;
  }
}
