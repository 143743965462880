@use '../../theme/color.scss'; 

  .note-filter-trigger {
    .anticon-book {
      position: absolute !important;
      right: 8px;
      background: #fff;
    }
  }
  .note-filter-trigger :active {
    .anticon-book {
      position: absolute !important;
    }
  }
  .noti-trigger {
    .anticon-bell {
      position: absolute !important;
      right: 30px;
      background: #fff;
    }
  }
  
