.ant-card.ant-card-bordered.statistic-container {
  .ant-card-body {
    .ant-table-wrapper {
      .ant-table-content {
        .ant-table-thead {
        }
        .ant-table-tbody {
          .ant-table-cell {
            padding: 10px 10px;
          }
        }
      }
    }
  }
}
